@font-face {
    font-family: 'naofont';
    src: url('naofont/naofont.eot?rxu53e');
    src: url('naofont/naofont.eot?rxu53e#iefix') format('embedded-opentype'),
    url('naofont/naofont.ttf?rxu53e') format('truetype'),
    url('naofont/naofont.woff?rxu53e') format('woff'),
    url('naofont/naofont.svg?rxu53e#naofont') format('svg');
    font-weight: normal;
    font-style: normal;
}

.nf {
    display: inline-block;
    font: normal normal normal 14px/1 'naofont';
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.nf-2x {
    font-size: 2em;
}

.nf-landscape:before {
    content: "\e900";
}

.nf-portrait:before {
    content: "\e901";
}

.nf-minimap:before {
    content: "\e902";
}

.nf-map:before {
    content: "\e903";
}

.nf-timeline:before {
    content: "\e904";
}

.nf-center-map:before {
    content: "\e905";
}

.nf-indexer-status:before {
    content: "\e906";
}

.nf-indexation:before {
    content: "\e907";
}

.nf-zone:before {
    content: "\e908";
}

.nf-first:before {
    content: "\e90c";
}

.nf-last:before {
    content: "\e90b";
}

.nf-fill:before {
    content: "\e90a";
}

.nf-text-color:before {
    content: "\e90d";
}

.nf-reset-color:before {
    content: "\e90e";
}

.nf-bottom-left:before {
    content: "\e90f";
}

.nf-bottom-right:before {
    content: "\e914";
}

.nf-top-left:before {
    content: "\e915";
}

.nf-top-right:before {
    content: "\e916";
}

.nf-x-twitter:before {
    content: "\e917";
}

.nf-x-twitter-square:before {
    content: "\e918";
}

.nf-facebook-f:before {
    content: "\e919";
}

.nf-facebook-square:before {
    content: "\e91a";
}

.nf-linkedin-in:before {
    content: "\e91b";
}

.nf-linkedin-square:before {
    content: "\e91c";
}

.nf-instagram:before {
    content: "\e91d";
}

.nf-instagram-square:before {
    content: "\e91e";
}

.nf-youtube:before {
    content: "\e91f";
}

.nf-youtube-square:before {
    content: "\e920";
}

.nf-highlight:before {
    content: "\e921";
}

.nf-brush:before {
    content: "\e922";
}

.nf-bluesky:before {
    content: "\e923";
}

.nf-bluesky-square:before {
    content: "\e924";
}
